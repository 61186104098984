<template>
  <div id="dashboard-reseller">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="header-pages-block d-md-flex justify-content-between">
      <h3 class="p-0">
        <b-icon class="mr-3" icon="columns" font-scale="1.5" variant="primary"></b-icon>
        {{ $t('pages.dashboard.title') }}
      </h3>
    </div>

    <hr class="mb-2" />

    <div class="counter-options mb-4">
      <div class="card">
        <div class="card-body m-0 p-0">
          <div class="row align-items-center justify-content-center col-12 p-0 m-0">
            <div class="col-sm-12 col-lg-4 p-2 m-0 justify-content-center">
              <b-button-group :class="'mx-auto w-100'">
                <b-button
                    :class="(counters.type === 'day') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('day')"
                >
                  {{ $t('pages.dashboard.period.day') }}
                </b-button>
                <b-button
                    :class="(counters.type === 'month') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('month')"
                >
                  {{ $t('pages.dashboard.period.month') }}
                </b-button>
                <b-button
                    :class="(counters.type === 'year') ? 'active' : ''"
                    variant="outline-primary"
                    v-on:click="eventChangeSwitch('year')"
                >
                  {{ $t('pages.dashboard.period.year') }}
                </b-button>
              </b-button-group>
              <div class="col-12 m-0 p-0 mt-4">
                <form @submit.prevent="initSubmitCountersPeriod" @click="counters.type = ''">
                  <div class="d-flex">
                    <div class="form-group w-50">
                      <label for="start-date">{{ $t('common.startDate') }}</label>
                      <Datepicker
                          input-class="w-100"
                          id="start-date"
                          v-model="counters.period.startDate"
                          :format="datepicker.formatDate"
                      ></Datepicker>
                    </div>

                    <div class="form-group w-50 ml-4">
                      <label for="end-date">{{ $t('common.endDate') }}</label>
                      <Datepicker
                          input-class="w-100"
                          id="end-date"
                          v-model="counters.period.endDate"
                          :format="datepicker.formatDate"
                      ></Datepicker>
                    </div>

                    <div class="mt-4">
                      <input type="submit" class="btn btn-sm btn-primary ml-2 mt-2" :value="$t('common.validate')" />
                    </div>
                    <div class="ml-2 mt-4">
                      <button class="btn btn-warning btn-sm mt-2" v-on:click="refreshCounters">
                        <b-icon icon="arrow-counterclockwise" font-scale="1.2" variant="white"></b-icon>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-sm-12 col-lg-8 p-O m-0 justify-content-center p-2">
              <FormPeriod
                  :data="formPeriod.form"
                  :list-scenarios="ScenarioModule.scenarios"
                  :list-resellers="ResellerModule.resellers"
                  :display-scenario="true"
                  :form-type="'sales'"
                  :is-reseller="true"
                  @submit="handleFormPeriod()"
                  @refresh="refreshApexChart"
                  :key="2"
              ></FormPeriod>
            </div>
          </div>
        </div>
      </div>
    </div>

    {{/* BLOCK COUNTERS */}}
    <div class="row mb-2">
      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-order border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="box-seam" font-scale="2" variant="primary"></b-icon>
            <div class="d-flex">
              <div class="w-50">
                <h6 class="text-uppercase mt-0">{{ $tc('common.order', 0).toUpperCase() }}</h6>
                <h2 class="my-2" id="counter-order">
                  <animated-number
                      :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countOrders : 0"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
              <div class="w-50">
                <h6 class="text-uppercase mt-0">
                  {{ $t('pages.dashboard.countersCards.orderCard.orderCancel').toUpperCase() }}
                </h6>
                <h2 class="my-2" id="counter-order-canceled">
                  <animated-number
                      :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countCancelledOrders : 0"
                      :formatValue="formatAnimatedNumber"
                      :duration="600"
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-quote border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="clipboard-check" font-scale="2" variant="success"></b-icon>
            <h6 class="text-uppercase mt-0">{{ $tc('common.quote', 0).toUpperCase() }}</h6>
            <h2 class="my-2" id="counter-quote">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.countQuotes : 0"
                  :formatValue="formatAnimatedNumber"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="graph-up" font-scale="2" variant="warning"></b-icon>
            <h6 class="text-uppercase mt-0">
              {{ $t('pages.dashboard.countersCards.salesCard.sales').toUpperCase() }}
            </h6>
            <h2 class="my-2" id="counter-sales">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.sales : 0"
                  :formatValue="formatAnimatedToPrice"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4">
        <div class="card shadow title-box-reseller border-0">
          <div class="card-body">
            <b-icon class="float-right " icon="clipboard-data" font-scale="2" variant="dark"></b-icon>
            <h6 class="text-uppercase mt-0">
              {{ $t('pages.dashboard.countersCards.salesCard.conversionRate').toUpperCase() }}
            </h6>
            <h2 class="my-2" id="counter-conversion-rate">
              <animated-number
                  :value="(DashboardModule.charts && DashboardModule.charts.periodCounters) ? DashboardModule.charts.periodCounters.periodConversionRate : 0"
                  :formatValue="formatAnimatedToPercentage"
                  :duration="600"
              />
            </h2>
          </div>
        </div>
      </div>
    </div>

    {{/* BLOCK GRAPHS */}}
    <div class="tabs-content" id="nav-tabContent">
      <b-card class="">
        <div class="card-header p-0">
          <div class="header-pages-block d-md-flex justify-content-between">
            <h3 class="p-0">
              <b-icon class="mr-3" icon="bar-chart" font-scale="1.5" variant="primary"></b-icon>
              {{ $t('pages.dashboard.graph.title') }}
            </h3>
          </div>
        </div>

        {{ /* BLOCK CHARTS */ }}
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            {{ /* CHARTS QUOTES / SCENARIO */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.quoteScenario') }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5"
                    type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartSales"
                    height="400"
                    :options="apexChart.quoteScenario.options"
                    :series="apexChart.quoteScenario.series"
                    :key="4"
                >
                </ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            {{ /* CHARTS ORDERS / SCENARIO */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.orderScenario') }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                  class="mt-5"
                  type="bar"
                  v-if="!formPeriod.form.reload"
                  ref="chartSales"
                  height="400"
                  :options="apexChart.orderScenario.options"
                  :series="apexChart.orderScenario.series"
                  :key="4"
                >
                </ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12 col-lg-12 col-xl-6">
            {{ /* CHARTS ORDERS / POSE */ }}
            <div class="card border-0 card-chart card-quote mt-4">
              <div class="card-header d-flex justify-content-between">
                <div>
                  {{ $t('pages.dashboard.graph.charts.pose') }}
                  <span class="font-weight-bold quote-start-date" >
                    {{ formatDate(formPeriod.form.startDate) }}
                  </span>
                  {{ $t('common.at') }}
                  <span class="font-weight-bold quote-end-date" data-date="">
                    {{ formatDate(formPeriod.form.endDate) }}
                  </span>
                </div>
              </div>
              <div class="card-body">
                {{ /* APEX CHART */ }}
                <ApexChart
                    class="mt-5"
                    type="bar"
                    v-if="!formPeriod.form.reload"
                    ref="chartSales"
                    height="400"
                    :options="apexChart.orderPose.options"
                    :series="apexChart.orderPose.series"
                    :key="4"
                >
                </ApexChart>
                <div class="text-center" v-else>
                  <b-spinner variant="success" label="Spinning"></b-spinner>
                  <div class="text-primary">{{ $t('common.loadingInProgress') }} ...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      tabs: {
        tabIndex: 0
      },
      datepicker: {
        formatDate: 'dd/MM/yyyy',
      },
      counters: {
        type: 'month',
        period: {
          startDate: null,
          endDate: null
        }
      },
      formPeriod: {
        form: {
          startDate: null,
          endDate: null,
          listScenarioId: [],
          listResellerId: [],
          isSend: false,
          reload: false
        }
      },
      apexChart: {
        quoteScenario: {
          options: {},
          series: [],
        },
        orderScenario: {
          options: {},
          series: [],
        },
        orderPose: {
          options: {},
          series: [],
        }
      }
    }
  },
  created() {
    this.showLoader()

    // COUNTERS
    this.counters.period.startDate = moment().format('YYYY-MM-01')
    this.counters.period.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()

    this.$store.dispatch('fetchCounters', {
      resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
      startDate : this.counters.period.startDate,
      endDate : this.counters.period.endDate
    })

    this.$store.dispatch('fetchScenarios', {
      perPage: null,
      page: null
    })
        .then((response) => {
          response.forEach((scenario) => {
            this.formPeriod.form.listScenarioId.push(scenario.id)
          })
        })

    // Date form
    this.formPeriod.form.startDate = moment().format('YYYY-MM-01')
    this.formPeriod.form.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()
  },
  updated() {
    this.hideLoader()
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- FORMAT ANIMATED NUMBER ------------------*/
    formatAnimatedNumber(value) {
      return `${value.toFixed(0)}`;
    },
    formatAnimatedToFloat(value) {
      return `${value.toFixed(2)}`;
    },
    formatAnimatedToPrice(value) {
      return `${value.toFixed(2)} €`;
    },
    formatAnimatedToPercentage(value) {
      return `${value.toFixed(2)} %`;
    },
    /*----------------- DATE ------------------*/
    formatDate(date)
    {
      return moment(date).format('DD/MM/YYYY')
    },
    /*----------------- TABS ------------------*/
    handleTabIndex(index) {
      this.tabs.tabIndex = index
    },
    refreshCounters() {
      this.showLoader();

      this.counters.period.startDate = moment().format('YYYY-MM-01')
      this.counters.period.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()

      this.$store.dispatch('fetchCounters', {
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate : moment(this.counters.period.startDate).format('YYYY-MM-DD'),
        endDate : moment(this.counters.period.endDate).format('YYYY-MM-DD')
      }).finally(() => {
        this.hideLoader();
      })
    },
    /*----------------- SWITCH PERIOD COUNTERS ------------------*/
    eventChangeSwitch(type) {
      this.showLoader()
      this.counters.type = type

      let startDate = null
      let endDate = null
      if(type === 'day') {
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
      } else if (type === 'month') {
        startDate = moment().format('YYYY-MM-01')
        endDate = moment().format('YYYY-MM-') + moment().daysInMonth()
      } else if (type === 'year') {
        startDate = moment().format('YYYY-01-01')
        endDate = moment().format('YYYY-12-') + moment().daysInMonth()
      }

      if(type !== 'period') {
        this.$store.dispatch('fetchCounters', {
          resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
          startDate : startDate,
          endDate : endDate
        })
      }
      if (startDate && endDate) {
        this.formPeriod.form.startDate = startDate;
        this.formPeriod.form.endDate = endDate;
        this.counters.period.startDate = startDate
        this.counters.period.endDate = endDate
        this.handleFormPeriod()
      }
    },
    /*----------------- SUBMIT FORM COUNTER PERIOD ------------------*/
    initSubmitCountersPeriod() {
      this.showLoader()

      this.$store.dispatch('fetchCounters', {
        resellerId: (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null,
        startDate : moment(this.counters.period.startDate).format('YYYY-MM-DD'),
        endDate : moment(this.counters.period.endDate).format('YYYY-MM-DD')
      }).then(() => {
        if (this.counters.period.startDate && this.counters.period.endDate) {
          this.formPeriod.form.startDate = moment(this.counters.period.startDate).format('YYYY-MM-DD');
          this.formPeriod.form.endDate = moment(this.counters.period.endDate).format('YYYY-MM-DD');

          this.handleFormPeriod()
        }
      }).catch(() => {
        this.hideLoader()
      })
    },
    /*----------------- SUBMIT FORM PERIOD ------------------*/
    handleFormPeriod() {
      this.showLoader();

      // Var form
      this.formPeriod.form.reload = true
      this.formPeriod.form.startDate = moment(this.formPeriod.form.startDate).format('YYYY-MM-DD')
      this.formPeriod.form.endDate = moment(this.formPeriod.form.endDate).format('YYYY-MM-DD')
      this.formPeriod.form.reseller = (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null

      this.$store.dispatch('fetchCharts', this.formPeriod.form)
        .then(() => {
          // QUOTE / SCENARIO (SERIES)
          this.apexChart.quoteScenario.series = this.setQuoteScenarioSeriesComputed
          // QUOTE / SCENARIO (LABEL)
          this.apexChart.quoteScenario.options = this.setQuoteScenarioLabelsComputed
          // ORDER / SCENARIO (SERIES)
          this.apexChart.orderScenario.series = this.setOrderScenarioSeriesComputed
          // ORDER / SCENARIO (LABEL)
          this.apexChart.orderScenario.options = this.setOrderScenarioLabelsComputed
          // ORDER / POSE (SERIES)
          this.apexChart.orderScenario.series = this.setOrderPoseSeriesComputed
          // ORDER / POSE (LABEL)
          this.apexChart.orderScenario.options = this.setOrderPoseLabelsComputed

          this.formPeriod.form.reload = false
        }).finally(() => {
        this.hideLoader();
      })
    },
    /*----------------- CHARTS ------------------*/
    getColorByType()
    {
      return {
        'quotes-scenario'  : "rgb(135, 206, 250)",
      }
    },
    getTranslateType()
    {
      return {
        'quotes-scenario' : {'en' : 'Quotes / Scenario', 'fr' : 'Devis / Scénario','es' : 'Quotes / Scenario',},
        'orders-scenario' : {'en' : 'Order / Scenario', 'fr' : 'Devis / Scénario', 'es' : 'Order / Scenario'},
        'orders-pose' : {'en' : 'Order / Pose', 'fr' : 'Commande / Pose', 'es' : 'Order / Pose'},
      }
    },
    setOptionsGlobal(id) {
      return {
        stacked: false,
        id: id,
        defaultLocale: 'fr',
        locales: [{
          name: 'fr',
          options: {
            months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            shortMonths: ['Jan', 'Feb', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Dec'],
            days: ['lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'],
            shortDays: ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'],
            toolbar: {
              exportToSVG: 'Télécharger SVG',
              exportToPNG: 'Télécharger PNG',
              exportToCSV: 'Télécharger CSV',
              selection: 'Sélection',
              selectionZoom: 'Zoom',
              zoomIn: 'Zoom +',
              zoomOut: 'Zoom -',
              pan: 'Panoramique',
              reset: 'Réinitialiser Zoom',
            }
          }
        }],
        fill: {
          type: 'pattern',
          pattern: {
            style: 'verticalLines',
            width: 6,
            height: 6,
            strokeWidth: 2
          }
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: 'bottom',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          itemMargin: {
            vertical: 10
          }
        },
      }
    },
    setConfigTooltip(type) {
      let that = this

      return {
        shared: true,
        intersect: false,
        y: {
          formatter: function (value) {
            return  value + " " + that.getTranslateType()[type]['fr'];
          }
        }
      }
    },
    refreshApexChart() {
      // Handle reset form
      this.formPeriod.form.startDate = moment().format('YYYY-MM-01')
      this.formPeriod.form.endDate = moment().format('YYYY-MM-') + moment().daysInMonth()
      this.formPeriod.form.isSend = false
      this.formPeriod.form.reseller = (this.$store.getters.userIsReseller) ? this.$store.getters.getCurrentUser.id : null
      // Reset list
      this.formPeriod.form.listScenarioId = []
      this.formPeriod.form.listResellerId = []

      this.$store.dispatch('fetchScenarios', {
        perPage: null,
        page: null
      })
        .then((response) => {
          response.forEach((scenario) => {
            this.formPeriod.form.listScenarioId.push(scenario.id)
          })
        })

      // Call charts for all data
      this.handleFormPeriod()
    },
    truncateString(string, limit) {
      if (string && (typeof string === 'string' || string instanceof String)) {
        if (string.length <= limit) {
          return string
        }
        return string.slice(0, limit) + "...";
      }
      return ''
    }
  },
  watch: {
    setQuoteScenarioSeriesComputed: function(newValue)
    {
      this.apexChart.quoteScenario.series = newValue
    },
    setQuoteScenarioLabelsComputed: function(newValue)
    {
      this.apexChart.quoteScenario.options = newValue
    },
    setOrderScenarioSeriesComputed: function(newValue)
    {
      this.apexChart.orderScenario.series = newValue
    },
    setOrderScenarioLabelsComputed: function(newValue)
    {
      this.apexChart.orderScenario.options = newValue
    },
    setOrderPoseSeriesComputed: function(newValue)
    {
      this.apexChart.orderPose.series = newValue
    },
    setOrderPoseLabelsComputed: function(newValue)
    {
      this.apexChart.orderPose.options = newValue
    }
  },
  computed: {
    setQuoteScenarioSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.quoteScenario'),
        data: (this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.series) ? this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.series : []
      }]
    },
    setQuoteScenarioLabelsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.label ? this.DashboardModule.charts.chartQuoteScenarioModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -90
          },
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.quoteScenario'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setOrderScenarioSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.orderScenario'),
        data: (this.DashboardModule.charts.chartOrderScenarioModel && this.DashboardModule.charts.chartOrderScenarioModel.formatValue.series)
            ? this.DashboardModule.charts.chartOrderScenarioModel.formatValue.series
            : []
      }]
    },
    setOrderScenarioLabelsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#13d8aa',
        ],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [
              '#13d8aa'
            ]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartOrderScenarioModel.formatValue.label ? this.DashboardModule.charts.chartOrderScenarioModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.orderScenario'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    setOrderPoseSeriesComputed() {
      return [{
        name: this.$t('pages.dashboard.graph.charts.pose'),
        data: (this.DashboardModule.charts.chartOrderPoseModel && this.DashboardModule.charts.chartOrderPoseModel.formatValue.series)
            ? this.DashboardModule.charts.chartOrderPoseModel.formatValue.series
            : []
      }]
    },
    setOrderPoseLabelsComputed() {
      return {
        chart: {
          type: 'bar',
          height: 700
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (val) => {
              return val
            }
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            },
          }
        },
        colors: [
          '#f48024',
        ],
        dataLabels: {
          enabled: true,
          formatter: (val) => {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: [
              '#f48024'
            ]
          }
        },
        xaxis: {
          categories: this.DashboardModule.charts.chartOrderPoseModel.formatValue.label ? this.DashboardModule.charts.chartOrderPoseModel.formatValue.label : [],
          labels: {
            enabled: true,
            formatter: (val) => {
              return this.truncateString(val, 15)
            },
            rotate: -60
          },
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          title: {
            text: this.$t('common.quantity')
          },
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            }
          }
        },
        title: {
          text: this.$t('pages.dashboard.graph.charts.pose'),
          floating: true,
          offsetY: -20,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      }
    },
    ...mapState(['DashboardModule']),
    ...mapState(['ResellerModule']),
    ...mapState(['ScenarioModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay'),
    AnimatedNumber: () => import('animated-number-vue'),
    ApexChart: () => import('vue-apexcharts'),
    FormPeriod: () => import('@/components/pages/dashboard/FormPeriod'),
    Datepicker: () => import('vuejs-datepicker')
  }
}
</script>
